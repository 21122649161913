import * as client_hooks from '../../../src/hooks.client.js';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45')
];

export const server_loads = [2];

export const dictionary = {
		"/": [~4],
		"/(app)/account": [~5,[2]],
		"/(app)/awards-management": [~6,[2]],
		"/(app)/badge-editor": [~7,[2]],
		"/(app)/character-builder": [~8,[2]],
		"/(app)/character-builder/[character]": [~9,[2]],
		"/(app)/character-profile/[character]": [~10,[2]],
		"/(app)/chat/[character]/[chat]": [~11,[2]],
		"/(app)/consent": [12,[2]],
		"/(app)/dashboard": [~13,[2]],
		"/(app)/down-message": [14,[2]],
		"/(public)/email-subscribe": [~37,[3]],
		"/(public)/faq": [38,[3]],
		"/(app)/featured-characters": [~15,[2]],
		"/(app)/gem-bounties": [~16,[2]],
		"/(app)/leaderboard": [~17,[2]],
		"/(app)/location-builder": [~18,[2]],
		"/(app)/location-builder/[location]": [~19,[2]],
		"/(app)/locations": [20,[2]],
		"/(public)/login": [~39,[3]],
		"/(app)/my-characters": [~21,[2]],
		"/(app)/my-locations": [~22,[2]],
		"/(app)/my-trinkets": [~23,[2]],
		"/(app)/news": [~24,[2]],
		"/(app)/offers": [~25,[2]],
		"/(public)/privacy": [40,[3]],
		"/(app)/profile/[userId]": [~26,[2]],
		"/(app)/recent-chats": [~27,[2]],
		"/(public)/roadmap": [41,[3]],
		"/(app)/search": [~28,[2]],
		"/(public)/signup": [~42,[3]],
		"/(app)/store": [~29,[2]],
		"/(app)/test/emails": [30,[2]],
		"/(public)/tos": [43,[3]],
		"/(app)/trinket-builder": [~31,[2]],
		"/(app)/trinket-builder/[trinket]": [~32,[2]],
		"/(app)/trinket-search": [33,[2]],
		"/(public)/unsubscribe": [~44,[3]],
		"/(app)/verify-email": [34,[2]],
		"/(public)/waiting-list": [45,[3]],
		"/(app)/world-builder": [35,[2]],
		"/(app)/world-builder/[world]": [36,[2]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.js';